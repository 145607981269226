import React from "react";
import { graphql } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import Masonry from "react-masonry-component";
import styled from "@emotion/styled";
import tw from "twin.macro";
// import ButtonGhost from "../components/Button/ButtonGhost";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ReviewCards from "../components/Reviews/ReviewCards";
import CallToAction from "../components/Repeating/CTA";
import ReviewSection from "../components/Reviews/ReviewSection";

const Page = ({ data }) => {
  const StyledReviews = styled.div`
    .masonry-grid {
      ${tw`-mb-8`}
      @media (min-width: 768px) {
        ${tw`-mx-3 -mb-10`}
      }
      @media (min-width: 1024px) {
        ${tw`-mx-5 -mb-12`}
      }
    }
    .masonry-item {
      @media (min-width: 768px) {
        width: calc(50% - 1.5rem);
      }
      @media (min-width: 1024px) {
        width: calc(50% - 2.5rem);
      }
    }
  `;

  const masonryOptions = {
    itemSelector: ".masonry-item",
    horizontalOrder: true,
  };

  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="BayView Reviews | Senior Assisted Living | Point Loma"
        description="Providing the type of care we’d want our own loved ones to have is near and dear to our hearts. We love hearing how we’ve made a difference in people’s lives."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <ReviewSection
        sectionTitle="What Our Residents and Their Families Say"
        sectionDesc="Providing the type of care we’d want our own loved ones to have is near and dear to our hearts, and we love hearing how we’ve made a difference in people’s lives. "
      />
      <section className="mb-20 md:mb-32">
        <div className="container">
          <StyledReviews>
            <Masonry className="masonry-grid" options={masonryOptions}>
              <ReviewCards />
            </Masonry>
          </StyledReviews>
        </div>
      </section>
      {/* <section className="bg-tertiary-50 lg:py-34 relative mb-20 pt-12 md:mb-32 md:py-32">
        <div className="absolute bottom-0 left-0 hidden  md:block">
          <StaticImage
            src="../../images/5.0 Amenities/1.0 Amenities-Hero.png"
            loading="eager"
            layout="constrained"
          />
        </div>
        <div className="container relative lg:py-24">
          <div className="grid gap-y-12 ">
            <div className="grid grid-cols-1 text-center md:max-w-[560px] md:gap-y-[25px] md:text-left">
              <h1 className=" md:text-white">Amenities at HarborView</h1>
              <p className="md:text-white">
                We provide a wide variety of amenities designed to deliver
                compassionate care and enrich the lives of each of our
                residents.
              </p>
              <ButtonGhost
                modal="hidden md:block"
                altStyle={2}
                text="Contact Us"
                className={
                  "mr-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
                }
              />
            </div>
            <div className="-mx-4 md:mx-0 md:hidden">
              <StaticImage
                src="../../images/5.0 Amenities/1.0 Amenities-mobile.jpg"
                loading="eager"
                className="h-full w-full"
                height={350}
              />
            </div>
          </div>
        </div>
      </section> */}
      <CallToAction phonenumber={data.contactInfo.phonenumber} />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }
  }
`;

export default Page;
